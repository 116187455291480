<template>
    <v-container class="py-0 m-0 h-full w-full">
        <v-row class="h-auto">
            <v-container class="md:flex flex-col md:justify-between md:items-center w-full my-2">
                <div class="md:flex md:justify-between md:items-center w-full">
                    <div>
                        <div class="text-3xl font-bold text-black">Balance: <span class="text-2xl">£{{ balance }}</span>
                        </div>
                    </div>
                    <div class="justify-start md:justify-end my-4 md:my-0">
                        <div class="flex flex-col sm:flex-row gap-4">
                            <v-text-field v-model="search" class="w-full rounded-lg sm:w-48" append-icon="mdi-magnify"
                                label="Search" dense solo hide-details></v-text-field>
                            <v-select :items="pageNumberArray" label="Page" v-model="pageNumber"
                                class="w-full rounded-lg sm:w-28" dense solo hide-details></v-select>

                            <filter-component :banks="banks" :accounts="accounts" :categories="categories"
                                :calendarDate="transactionDate" :requiredFilters="requiredFilters"
                                @bankChangedCallback="bankChangedCallback" @accountChangedCallback="accountChangedCallback"
                                @categoryChangedCallback="categoryChangedCallback"
                                @transactionDateChangedCallback="transactionDateChangedCallback">
                            </filter-component>

                        </div>
                    </div>
                </div>
            </v-container>
        </v-row>

        <v-row class="h-auto">
            <v-skeleton-loader class="h-full w-full" v-if="dataLoading"
                type="table-thead, table-tbody@2"></v-skeleton-loader>
            <v-container v-else class="h-full">
                <v-simple-table class="h-full overflow-y-scroll">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">#</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Category</th>
                                <th class="text-left">Date</th>
                                <th class="text-left">Amount</th>
                                <th class="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in transactions" :key="i">
                                <td>{{ pageNumber !== 0 ? (pageNumber * 20) + i + 1 : i + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.category[0] }}</td>
                                <td>{{ item.date }}</td>
                                <td><span class="font-bold" :class="item.amount < 0 ? 'text-red-500' : 'text-green-600 '">
                                        {{
                                            getAmountSign(item.amount) + item.amount }}</span></td>
                                <v-dialog max-width="400px" color="red lighten-1">
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="cursor-pointer" v-on="on" v-bind="attrs"><v-icon>mdi-eye</v-icon></td>
                                    </template>
                                    <v-card rounded="xl" color="">
                                        <transaction-details :item="item"></transaction-details>
                                    </v-card>
                                </v-dialog>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-container>
        </v-row>

    </v-container>
</template>

<script>
import moment from 'moment'
// import axios from 'axios'
// import { Messages } from '@/utils/constants';
import TransactionDetails from '../../components/Transactions/TransactionDetails.vue';
import FilterComponent from '../../components/Partials/FilterComponent.vue';

export default {
    components: { TransactionDetails, FilterComponent },
    data() {
        return {
            // dataLoading: true,
            dataLoading: false,
            showTxDetails: false,
            viewingItem: null,
            // balance: '',
            balance: '100.0',
            pageNumber: 0,
            maxPageSize: 0,
            accountId: null,
            itemId: null,
            selectedBank: null,
            category: '',
            pageNumberArray: [],
            // banks: [],
            banks: [
        {
            "userId": 13,
            "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
            "bank": "Santander (UK) - Personal and Business",
            "status": "ACTIVE",
            "balance": null,
            "accounts": [
                {
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "balance": 100.0,
                    "mask": "0000",
                    "name": "Plaid Checking",
                    "officialName": "Plaid Gold Standard 0% Interest Checking",
                    "type": "DEPOSITORY",
                    "subtype": "CHECKING"
                }
            ]
        },
        {
            "userId": 13,
            "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
            "bank": "Chase",
            "status": "ACTIVE",
            "balance": null,
            "accounts": [
                {
                    "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                    "accountId": "rb8k6gMgVDsZQq8vJPAqtrpydQNkR9F7zwGm9",
                    "balance": 100.0,
                    "mask": "0000",
                    "name": "Plaid Checking",
                    "officialName": "Plaid Gold Standard 0% Interest Checking",
                    "type": "DEPOSITORY",
                    "subtype": "CHECKING"
                },
                {
                    "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                    "accountId": "z3yp6aWaqruy8oB3lJmoFQLz693wGdSlnaejN",
                    "balance": 200.0,
                    "mask": "1111",
                    "name": "Plaid Saving",
                    "officialName": "Plaid Silver Standard 0.1% Interest Saving",
                    "type": "DEPOSITORY",
                    "subtype": "SAVINGS"
                }
            ]
        }
    ],
            // categories: [],
            categories: ["Food and Drink","Shops","Travel","Travel","Travel"],
            // transactionDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
            // transactionDate: [moment().format("YYYY-MM-DD")],
            transactionDate: null,
            currentDate: moment().format("YYYY-MM-DD"),
            // transactions: [],
            transactions: [
                {
                    "id": "63d5832dab26e85596c14e9c",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "22001000",
                    "category": [
                        "Travel",
                        "Airlines and Aviation Services"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "United Airlines",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": -500.0,
                    "absAmount": 500.0,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-28",
                    "pending": false,
                    "transactionId": "jqLjq1jQjpc6MQ5xbm9WC8l4WB3DPpfZEqG59",
                    "merchantName": "United Airlines",
                    "authorizedDate": "2023-01-28",
                    "dayMonth": "28-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-28",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.555"
                },
                {
                    "id": "63d5832dab26e85596c14e9d",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": 15,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "13005032",
                    "category": [
                        "Food and Drink",
                        "Restaurants",
                        "Fast Food"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": "3322"
                    },
                    "name": "McDonald's",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 12.0,
                    "absAmount": 12.0,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-27",
                    "pending": false,
                    "transactionId": "7MR6Md6z6PIeQKZP8BvJskgZxovyRDiMZL1Wr",
                    "merchantName": "McDonald's",
                    "authorizedDate": "2023-01-27",
                    "dayMonth": "27-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-27",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-03-29T15:22:00.223"
                },
                {
                    "id": "63d5832dab26e85596c14e9e",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "13005043",
                    "category": [
                        "Food and Drink",
                        "Restaurants",
                        "Coffee Shop"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "Starbucks",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 4.33,
                    "absAmount": 4.33,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-27",
                    "pending": false,
                    "transactionId": "ekMdk7dNd6IX3NyMoqGEC6yRXEjZDBt7g1VNn",
                    "merchantName": "Starbucks",
                    "authorizedDate": "2023-01-27",
                    "dayMonth": "27-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-27",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.622"
                },
                {
                    "id": "63d5832dab26e85596c14e9f",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "19013000",
                    "category": [
                        "Shops",
                        "Computers and Electronics"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "SparkFun",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 89.4,
                    "absAmount": 89.4,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-26",
                    "pending": false,
                    "transactionId": "Q4pE4vEbEzTkQBdEnDeVS4qpjKb7dGi31Eax4",
                    "merchantName": null,
                    "authorizedDate": "2023-01-26",
                    "dayMonth": "25-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-26",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.625"
                },
                {
                    "id": "63d5832dab26e85596c14ea0",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "22016000",
                    "category": [
                        "Travel",
                        "Taxi"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "Uber 072515 SF**POOL**",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 6.33,
                    "absAmount": 6.33,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-13",
                    "pending": false,
                    "transactionId": "Z1Ev1rvlvbtgyl1anNkBs14egNXJbMIr3Vb5o",
                    "merchantName": "Uber",
                    "authorizedDate": "2023-01-13",
                    "dayMonth": "12-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-13",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.629"
                }
            ],
            search: '',
            requiredFilters: {
                banks: true,
                accounts: true,
                categories: true,
                transactionDate: true,
                rangePicker: true,
                searchButton: false
            }
        }
    },
    computed: {
        accounts() {
            return this.selectedBank ? this.selectedBank.accounts : []
        },
        userId() {
            return this.$session.get('user').id
        },
    },
    async created() {
        // await this.loadAllDataFromAPI();
    },
    watch: {
        pageNumber() {
            this.dataLoading = true
            this.getTransactions()
        },
        maxPageSize() {
            this.pageNumberArray = Array.from({ length: this.maxPageSize + 1 }, (_, i) => i)
            this.pageNumber = 0
        },
        itemId() {
            this.search = ''
            this.dataLoading = true
            this.getMaxPageSize()
            this.getTransactions()
            this.getBalance()
        },
        search() {
            if (this.search.length > 3 || this.search.length === 0) {
                this.dataLoading = true
                this.getMaxPageSize()
                this.getTransactions()
            }
        }

    },
    methods: {
        async loadAllDataFromAPI() {
            // request items - institution becomes bank. Must be set by default
            await this.getBanks()

            // request total page numbers
            await this.getMaxPageSize()

            // request categories for the user
            await this.getCategories()

            // load balance
            await this.getBalance()

            // request transactions
            await this.getTransactions()
        },
        getAmountSign(amount) {
            return amount < 0 ? '' : '+'
        },
        showDetailedTransaction(item) {
            this.viewingItem = item
            this.showTxDetails = true
        },
        async getBanks() {
            // try {
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/items', { user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
            //     this.banks = response.data.banks
            //     if (this.banks.length > 0) {
            //         this.selectedBank = this.banks[0]
            //         this.itemId = this.banks[0].itemId
            //         this.accountId = this.selectedBank.accounts[0].accountId
            //     }
            // } catch (error) {
            //     this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.error, type: Messages.ERROR })
            //     // console.log('Failed to load user banks');
            // }
        },
        async getBalance() {
            // let payload = {
            //     item_id: this.itemId,
            //     user_id: this.userId,
            // }
            // const response = await axios.post(process.env.VUE_APP_API_URL + '/balance', payload, { headers: { "Content-Type": "application/json" } })
            // this.balance = response.data
        },
        async getMaxPageSize() {
            // let payload = {
            //     user_id: this.userId,
            //     item_id: this.itemId,
            //     category: this.category,
            //     date: this.transactionDate,
            //     account_id: this.accountId,
            //     search: this.search
            // }
            // const response = await axios.post(process.env.VUE_APP_API_URL + '/transactions/page', payload, { headers: { "Content-Type": "application/json" } })
            // this.maxPageSize = response.data
        },
        async getCategories() {
            // try {
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/transaction/categories', { item_id: this.itemId, user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
            //     this.categories = response.data
            // } catch (error) {
            //     this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.error, type: Messages.ERROR })
            //     // console.log('Failed to load categories');
            // }
        },
        async getTransactions() {
            // try {
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/transactions',
            //         {
            //             user_id: this.userId,
            //             item_id: this.itemId,
            //             category: this.category,
            //             date: this.transactionDate,
            //             page_number: this.pageNumber,
            //             account_id: this.accountId,
            //             search: this.search
            //         },

            //         { headers: { "Content-Type": "application/json" } })

            //     this.transactions = response.data.transactions
            //     this.dataLoading = false
            // } catch (error) {
            //     this.dataLoading = false
            //     if (error.response.data.errors.length > 0) {
            //         // concat each error message
            //         let errorMessage = ''
            //         error.response.data.errors.forEach((error) => {
            //             errorMessage += error + ', '
            //         })
            //         this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     }
            //     // this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.errors, type: Messages.ERROR})
            //     // console.log(JSON.stringify(error.response.data));
            //     // console.log('Failed to load transactions');
            // }
        },
        bankChangedCallback(val) {
            this.selectedBank = val
            this.search = ''
            this.itemId = this.selectedBank.itemId
            this.accountId = this.selectedBank.accounts[0].accountId
            this.getMaxPageSize()
        },
        accountChangedCallback(val) {
            this.accountId = val
            this.search = ''
            this.dataLoading = true
            this.getMaxPageSize()
            this.getTransactions()
            this.getBalance()
        },
        categoryChangedCallback(val) {
            this.category = val
            this.search = ''
            this.dataLoading = true
            this.getMaxPageSize()
            this.getTransactions()
        },
        transactionDateChangedCallback(val) {
            this.transactionDate = val
            this.dataLoading = true
            this.search = ''
            this.getMaxPageSize()
            this.getTransactions()
        }
    }
}
</script>

<style></style>