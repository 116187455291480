<template>
    <v-dialog v-model="dialog" :persistent="subscriptions.length > 0" max-width="600px">

        <template v-slot:activator="{ on, attrs }">
            <!-- Add button -->
            <v-btn class="mx-auto my-auto btn" color="red" rounded v-bind="attrs" v-on="on">
                <span class="text-white xs:block ml-2 text-capitalize">Link Transactions</span>
                <v-icon right color="white">mdi-link-variant</v-icon>
            </v-btn>
        </template>

        <v-card color="">
            <v-card-title>
                <span class="headline">Link or Unlink Bill with Transactions</span>
            </v-card-title>
            <div v-if="subscriptions.length > 0">
                <v-card-subtitle class="mt-4">Please note this request may take some time.</v-card-subtitle>
            </div>
            <v-card-subtitle v-else class="mt-4">No Bill found. Please first add one to link with transactions</v-card-subtitle>

            <v-card-text>
                <v-container fluid>
                    <div class="my-2">
                        <div class="space-y-3">
                            <div class="mb-2 flex flex-col">
                                <v-list-item-group multiple
                                    active-class="red--text text--accent-4">
                                    <v-list-item v-for="(subscription, i) in subscriptions" :key="i">
                                            <v-list-item-action>
                                                <v-checkbox v-model="subscription.txLinked" color="red"></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>{{ subscription.merchantName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ subscription.amount }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </div>
                        </div>
                    </div>
                </v-container>
            </v-card-text>

            <v-card-actions v-if="subscriptions.length > 0">
                <div class="flex flex-col px-5 py-4 border-t border-slate-200">
                    <div class="flex justify-between space-x-2">
                        <v-btn class="mb-4" small rounded @click="dialog = false">Cancel</v-btn>
                        <!-- <v-btn class="mb-4" :loading="loading" small rounded dark color="red" @click="save">Save</v-btn> -->
                        <v-btn class="mb-4" :loading="loading" small rounded dark color="red" >Save</v-btn>
                    </div>
                </div>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
import { Messages } from '@/utils/constants';

export default {
    props: ['subscriptions'],
    data() {
        return {
            dialog: false,
            loading: false,
            mappedSubscriptions: [],
        }
    },

    watch: {
        subscriptions: {
            immediate: true,
                deep: true,
                handler(val) {
                    this.mappedSubscriptions = val
                }
        }
    },

    methods: {
        async save() {
            try {
                let payload = this.getPayload()
                console.log('payload: '+payload);
                this.loading = true
                const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/link', payload, {headers: {"Content-Type": "application/json"}})
                this.message = response.data
                this.dialog = false
                this.loading = false
                this.$$emit('reloadSubscriptions')
            } catch (error) {
                this.loading = false
                this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.error, type: Messages.ERROR})
                // console.log('Failed to link subscription'); 
            }
        },
        getPayload() {
            let array = []
            this.mappedSubscriptions.map((item) => {
                let obj = {
                    id: item.id,
                    user_id: item.userId,
                    merchant_name: item.merchantName,
                    amount: item.amount,
                    due_date: item.dueDate,
                    item_id: item.itemId,
                    bank_name: item.bankName,
                    tx_linked: item.txLinked
                }

                array.push(obj)
            })
            return array
        }
    }

}
</script>

<style>

</style>