<template>
    <div>
        <v-container fluid>
        <v-row>
            <v-col cols="12">
            <div class="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center py-4 sm:pt-0">
                <div class="min-w-screen h-screen overflow-x-hidden overflow-y-auto fixed  my-auto  outline-none focus:outline-none  items-center flex justify-center">
                    <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                    <div class="">
                        <div class="text-center p-5 flex-auto justify-center align-middle">
                            <svg class="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"></path></svg>
                            <h2 class="text-xl font-bold py-4 ">Congrats! Your account is connected</h2>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import axios from 'axios'

export default {
    data() {
        return {
            plaidUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
            error: null,
            metadata: null,
            eventName: null,
        }
    },
    created() {
        this.requestLinkToken()
        this.loadScript(this.plaidUrl)
        .then(this.onScriptLoaded)
        .catch(this.onScriptError);
    },
    beforeDestroy() {
        if (window.linkHandler) {
        window.linkHandler.exit();
        }
    },
    computed: {
        ...mapGetters({
            linkToken: 'getLinkToken'
        }),
        userId() {
            return this.$session.get('user').id
        }
    },
    methods: {
        async requestLinkToken() {
            await this.$store.dispatch('requestLinkToken', this.userId)
        },
        updateLoading(loading) {
            this.$emit('updateLoading', loading)
        },
        onScriptError(error) {
            this.error = error;
        },
        onScriptLoaded() {
            let props = {
                receivedRedirectUri: window.location.href,
                token: this.linkToken,
                onExit: this.onExit,
                onEvent: this.onEvent,
                onSuccess: this.onSuccess,
            };

            window.linkHandler = window.Plaid.create(props);
            this.$emit("plaidLoaded");
            this.onLoad();
            window.linkHandler.open();
        },
        handleOnClick() {
            this.updateLoading(true)
            const institution = this.institution || null;
            if (window.linkHandler) {
                window.linkHandler.open(institution);
            }
        },
        loadScript(src) {
            return new Promise(function (resolve, reject) {
                if (document.querySelector('script[src="' + src + '"]')) {
                resolve();
                return;
                }

                const el = document.createElement("script");

                el.type = "text/javascript";
                el.async = true;
                el.src = src;

                el.addEventListener("load", resolve);
                el.addEventListener("error", reject);
                el.addEventListener("abort", reject);

                document.head.appendChild(el);
            });
        },

        onExit(error, metadata) {
            this.loading = false
            this.error = error;
            this.metadata = metadata;
        },
        onEvent(eventName, metadata) {
            this.eventName = eventName;
            this.metadata = metadata;
        },
        onLoad() {
        },
        onSuccess(token) {
            // send public token to server to exchange for access token
            let payload = {user_id: this.userId, public_token: token}
            this.exchangePublicToken(payload)
            // .then(window.location.href = '/')
        },

        async exchangePublicToken() {},
        // async exchangePublicToken(payload) {
        //     try {
        //         await axios.post(process.env.VUE_APP_API_URL + '/exchange-token', payload, {headers: {"Content-Type": "application/json"}})
        //     } catch (error) {
        //         this.error = error
        //     }
        // }
        
    },
    watch: {
        $props: {
        handler(newVal) {
            if (newVal.token) {
            this.onScriptLoaded();
            }
        },
        deep: true,
        },
    },
}
</script>

<style>

</style>