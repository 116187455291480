<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="350">
            <v-card>
                <div class="text-h5 justify-center flex py-4">
                    <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z">
                        </path>
                    </svg>
                </div>
                <v-card-title class="text-h5 justify-center flex">
                    Confirmation
                </v-card-title>
                <v-card-text class="text-center">{{ message }}</v-card-text>
                <v-card-actions>
                    <v-btn :hidden="loading" color="darken-1" text @click="cancelDelete">
                        Cancel
                    </v-btn>
                    <v-spacer v-if="!loading"></v-spacer>
                    <v-btn :loading="loading" :class="loading ? 'mx-auto' : ''" color="red darken-1" text @click="confirmDelete">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
    
<script>
export default {
    props: ['dialog', 'message'],
    data() {
        return {
            loading: false, 
        }
    },

    methods: {
        confirmDelete() {
            this.loading = true
            this.$emit('confirmDelete')
        },
        cancelDelete() {
            this.$emit('cancelDelete')
        }
    }
}
</script>