<template>
    <v-container class="py-10 w-full h-auto">
        <v-row class="md:flex md:justify-between md:items-center w-full h-1/5 my-2">
            <div>
                <div class="text-3xl font-bold text-black">Your Bills</div>
            </div>
            <div class="justify-start md:justify-end my-4 md:my-0">
                <div class="flex flex-col sm:flex-row gap-4 justify-between">

                    <link-transactions :subscriptions="subscriptions"
                        @reloadSubscriptions="getSubscriptions"></link-transactions>

                    <v-btn color="teal" @click="openForm" rounded><span class="text-white xs:block ml-2 text-capitalize">Add
                            Bill</span><v-icon right color="white">mdi-plus</v-icon></v-btn>
                    <v-dialog width="400px" color="red lighten-1" persistent v-model="showForm">
                        <v-card color="">
                            <v-card-title>
                                <span class="headline">Bill Details</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container fluid>
                                    <div class="my-4">
                                        <div class="space-y-3">
                                            <div class="mb-2 flex flex-col">
                                                <div class="mb-2">
                                                    <label class="block text-sm font-medium mb-1" for="name">Name <span
                                                            class="text-gray-500">*</span></label>
                                                    <input v-model="formData.name" id="name"
                                                        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text" required />
                                                </div>
                                                <div class="mb-2">
                                                    <label class="block text-sm font-medium mb-1"
                                                        for="merchantName">Merchant Name <span
                                                            class="text-gray-500">*</span></label>
                                                    <input v-model="formData.merchantName"
                                                        placeholder="eg Amazon, Transport for London" id="merchantName"
                                                        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text" required />
                                                </div>
                                                <div class="mb-2">
                                                    <label class="block text-sm font-medium mb-1" for="amount">Amount <span
                                                            class="text-gray-500">*</span></label>
                                                    <input v-model="formData.amount" id="amount"
                                                        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text" required />
                                                </div>
                                                <div class="mb-2">
                                                    <label class="block text-sm font-medium mb-1" for="dueDate">Next Due
                                                        Date <span class="text-gray-500">*</span></label>
                                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                        transition="scale-transition" offset-y min-width="auto"
                                                        color="red lighten-1">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="formData.dueDate" label="Day and Month"
                                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                                v-on="on" color="red lighten-1"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="formData.dueDate" no-title scrollable
                                                            color="red lighten-1" @change="menu = false" :min="minDate">
                                                        </v-date-picker>
                                                    </v-menu>
                                                </div>
                                                <div class="mb-2">
                                                    <label class="block text-sm font-medium mb-1" for="currency">What card
                                                        do you pay with <span class="text-gray-500">*</span></label>
                                                    <v-select v-model="formData.itemId" :items="banks" label="Select Bank"
                                                        item-value="itemId" item-text="bank" dense solo
                                                        hide-details></v-select>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <div class="flex flex-col px-5 py-4 border-t border-slate-200">
                                    <div class="flex justify-between space-x-2">
                                        <v-btn class="mb-4" small rounded @click="hideForm">Cancel</v-btn>
                                        <v-btn class="mb-4" :loading="loading" small rounded dark color="red"
                                            >Save</v-btn>
                                        <!-- <v-btn class="mb-4" :loading="loading" small rounded dark color="red"
                                            @click="save">Save</v-btn> -->
                                    </div>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </v-row>

        <v-row class="w-full h-4/5">
            <v-window v-model="steps" class="w-full h-full">
                <v-window-item :value="1">
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Merchant</th>
                                        <th class="text-left">Amount</th>
                                        <th class="text-left">Due Date</th>
                                        <th class="text-left">Bank</th>
                                        <th class="text-left"></th>
                                        <th class="text-left"></th>
                                        <th class="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in subscriptions" :key="i">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.merchantName }}</td>
                                        <td>{{ item.amount }}</td>
                                        <td>{{ item.dueDate }}</td>
                                        <td>{{ item.bankName }}</td>
                                        <td @click="viewItem(item)" class="cursor-pointer"><v-icon
                                                color="blue">mdi-eye</v-icon></td>
                                        <td class="cursor-pointer" @click="showEditForm(item)"><v-icon
                                                color="green">mdi-pen</v-icon></td>
                                        <td @click="deleteItem(item)" class="cursor-pointer"><v-icon
                                                color="red">mdi-delete</v-icon></td>

                                        <v-dialog max-width="400px" color="red lighten-1" v-if="editFormVisible"
                                            v-model="editFormVisible" :key="i" persistent>
                                            <v-card color="">
                                                <v-card-title>
                                                    <span class="headline">Subscription Details</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container fluid>
                                                        <div class="my-4">
                                                            <div class="space-y-3">
                                                                <div class="mb-2 flex flex-col">
                                                                    <div class="mb-2">
                                                                        <label class="block text-sm font-medium mb-1"
                                                                            for="name">Name <span
                                                                                class="text-gray-500">*</span></label>
                                                                        <input v-model="editItem.name" id="name"
                                                                            class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                            type="text" required />
                                                                    </div>
                                                                    <div class="mb-2">
                                                                        <label class="block text-sm font-medium mb-1"
                                                                            for="merchantName">Merchant <span
                                                                                class="text-gray-500">*</span></label>
                                                                        <input v-model="editItem.merchantName"
                                                                            id="merchantName"
                                                                            class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                            type="text" required />
                                                                    </div>
                                                                    <div class="mb-2">
                                                                        <label class="block text-sm font-medium mb-1"
                                                                            for="amount">Amount <span
                                                                                class="text-gray-500">*</span></label>
                                                                        <input v-model="editItem.amount" id="amount"
                                                                            class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                            type="text" required />
                                                                    </div>
                                                                    <div class="mb-2">
                                                                        <label class="block text-sm font-medium mb-1"
                                                                            for="dueDate">Due Date <span
                                                                                class="text-gray-500">*</span></label>
                                                                        <v-menu :close-on-content-click="true"
                                                                            transition="scale-transition" offset-y
                                                                            min-width="auto" color="red lighten-1">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-text-field v-model="editItem.dueDate"
                                                                                    label="Select Due Date"
                                                                                    prepend-icon="mdi-calendar" readonly
                                                                                    v-bind="attrs" v-on="on"
                                                                                    color="red lighten-1"></v-text-field>
                                                                            </template>
                                                                            <v-date-picker v-model="editItem.dueDate"
                                                                                no-title scrollable color="red lighten-1"
                                                                                :min="minDate">
                                                                            </v-date-picker>
                                                                        </v-menu>
                                                                    </div>
                                                                    <div class="mb-2">
                                                                        <label class="block text-sm font-medium mb-1"
                                                                            for="bank">Bank <span
                                                                                class="text-gray-500">*</span></label>
                                                                        <v-select v-model="editItem.itemId" :items="banks"
                                                                            label="Select Bank" item-value="itemId"
                                                                            item-text="bank" dense solo
                                                                            hide-details></v-select>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-container>
                                                </v-card-text>

                                                <v-card-actions>
                                                    <div class="flex flex-col px-5 py-4 border-t border-slate-200">
                                                        <div class="flex justify-between space-x-2">
                                                            <v-btn class="mb-4" small rounded
                                                                @click="hideEditForm">Cancel</v-btn>
                                                            <v-btn class="mb-4" :loading="loading" small rounded dark
                                                                color="red">Save</v-btn>
                                                            <!-- <v-btn class="mb-4" :loading="loading" small rounded dark
                                                                color="red" @click="update">Save</v-btn> -->
                                                        </div>
                                                    </div>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </tr>
                                    <alert-modal :dialog="deleteDialog"
                                        :message="'Deleting this will also unlink all of its transaction. Are you sure?'"
                                        @cancelDelete="cancelDelete"></alert-modal>
                                    <!-- <alert-modal :dialog="deleteDialog"
                                        :message="'Deleting this will also unlink all of its transaction. Are you sure?'"
                                        @confirmDelete="confirmDelete" @cancelDelete="cancelDelete"></alert-modal> -->
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-window-item>

                <v-window-item :value="2" class="w-full h-full">
                    <v-card class="w-full h-full">
                        <v-card-title class="">
                            <div class="flex justify-between w-full">
                                <span>{{ viewingItem.merchantName }}</span>
                                <v-card-subtitle>Next Due: {{ viewingItem.dueDate }}</v-card-subtitle>
                            </div>
                            <div class="flex justify-between">
                                <v-row>
                                    <v-col cols="3">
                                        <v-btn dark @click="steps = 1"><v-icon>mdi-arrow-left</v-icon></v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="4">
                                        <div class="flex">
                                            <v-select :items="pageNumberArray" label="Page" v-model="pageNumber"
                                                class="w-28" dense solo hide-details></v-select>
                                            <v-select item-color="red lighten-1" color="red lighten-1" :items="years"
                                                label="Filter by Year" v-model="transactionDate" class="w-28" dense solo
                                                hide-details></v-select>
                                            <!-- <v-dialog ref="dialog" width="290px" color="red lighten-1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="transactionDate"
                                                    append-icon="mdi-calendar"
                                                    label="Filter by Year"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="red lighten-1"
                                                    class="v-input v-input--hide-details  v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed  primary--text"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    scrollable
                                                    color="red lighten-1"
                                                    no-title
                                                    v-model="transactionDate"
                                                    type="year"
                                                >
                                                </v-date-picker>
                                            </v-dialog> -->
                                        </div>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-card-title>

                        <v-card-text class="h-full">
                            <v-simple-table class="h-2/3 overflow-y-scroll">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Merchant</th>
                                            <th class="text-left">Date</th>
                                            <th class="text-left">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in transactions" :key="i">
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.amount }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-window-item>
            </v-window>

        </v-row>

    </v-container>
</template>

<script>
import moment from 'moment'
// import axios from 'axios'
import AlertModal from '../../components/Partials/AlertModal.vue'
import LinkTransactions from './LinkTransactions.vue'
// import { Messages } from '@/utils/constants';

export default {
    components: { AlertModal, LinkTransactions },
    data() {
        return {
            menu: false,
            steps: 1,
            // banks: [],
            banks: [
                {
                    "userId": 13,
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "bank": "Santander (UK) - Personal and Business",
                    "status": "ACTIVE",
                    "balance": null,
                    "accounts": [
                        {
                            "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                            "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                            "balance": 100.0,
                            "mask": "0000",
                            "name": "Plaid Checking",
                            "officialName": "Plaid Gold Standard 0% Interest Checking",
                            "type": "DEPOSITORY",
                            "subtype": "CHECKING"
                        }
                    ]
                },
                {
                    "userId": 13,
                    "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                    "bank": "Chase",
                    "status": "ACTIVE",
                    "balance": null,
                    "accounts": [
                        {
                            "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                            "accountId": "rb8k6gMgVDsZQq8vJPAqtrpydQNkR9F7zwGm9",
                            "balance": 100.0,
                            "mask": "0000",
                            "name": "Plaid Checking",
                            "officialName": "Plaid Gold Standard 0% Interest Checking",
                            "type": "DEPOSITORY",
                            "subtype": "CHECKING"
                        },
                        {
                            "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
                            "accountId": "z3yp6aWaqruy8oB3lJmoFQLz693wGdSlnaejN",
                            "balance": 200.0,
                            "mask": "1111",
                            "name": "Plaid Saving",
                            "officialName": "Plaid Silver Standard 0.1% Interest Saving",
                            "type": "DEPOSITORY",
                            "subtype": "SAVINGS"
                        }
                    ]
                }
            ],
            showForm: false,
            editFormVisible: false,
            deleteDialog: false,
            deletingItem: null,
            loading: false,
            transactionDate: null,
            formData: {
                userId: '',
                name: '',
                merchantName: '',
                amount: '',
                dueDate: '',
                itemId: '',
                bankName: '',
            },
            editItem: {
                id: '',
                userId: '',
                name: '',
                merchantName: '',
                amount: '',
                dueDate: '',
                bankName: '',
                itemId: '',
            },
            viewingItem: {},
            // subscriptions: [],
            subscriptions: [
                {
                    "id": 1,
                    "userId": 13,
                    "name": "Netflix",
                    "merchantName": "netflix.com",
                    "amount": 9.99,
                    "bankName": "Monzo",
                    "dueDate": "",
                    "itemId": "",
                    "txLinked": false
                }
            ],
            // transactions: [],
            transactions: [
                {
                    "id": "63d5832dab26e85596c14e9c",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "22001000",
                    "category": [
                        "Travel",
                        "Airlines and Aviation Services"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "United Airlines",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": -500.0,
                    "absAmount": 500.0,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-28",
                    "pending": false,
                    "transactionId": "jqLjq1jQjpc6MQ5xbm9WC8l4WB3DPpfZEqG59",
                    "merchantName": "United Airlines",
                    "authorizedDate": "2023-01-28",
                    "dayMonth": "28-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-28",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.555"
                },
                {
                    "id": "63d5832dab26e85596c14e9d",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": 15,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "13005032",
                    "category": [
                        "Food and Drink",
                        "Restaurants",
                        "Fast Food"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": "3322"
                    },
                    "name": "McDonald's",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 12.0,
                    "absAmount": 12.0,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-27",
                    "pending": false,
                    "transactionId": "7MR6Md6z6PIeQKZP8BvJskgZxovyRDiMZL1Wr",
                    "merchantName": "McDonald's",
                    "authorizedDate": "2023-01-27",
                    "dayMonth": "27-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-27",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-03-29T15:22:00.223"
                },
                {
                    "id": "63d5832dab26e85596c14e9e",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "13005043",
                    "category": [
                        "Food and Drink",
                        "Restaurants",
                        "Coffee Shop"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "Starbucks",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 4.33,
                    "absAmount": 4.33,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-27",
                    "pending": false,
                    "transactionId": "ekMdk7dNd6IX3NyMoqGEC6yRXEjZDBt7g1VNn",
                    "merchantName": "Starbucks",
                    "authorizedDate": "2023-01-27",
                    "dayMonth": "27-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-27",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.622"
                },
                {
                    "id": "63d5832dab26e85596c14e9f",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "19013000",
                    "category": [
                        "Shops",
                        "Computers and Electronics"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "SparkFun",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 89.4,
                    "absAmount": 89.4,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-26",
                    "pending": false,
                    "transactionId": "Q4pE4vEbEzTkQBdEnDeVS4qpjKb7dGi31Eax4",
                    "merchantName": null,
                    "authorizedDate": "2023-01-26",
                    "dayMonth": "25-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-26",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.625"
                },
                {
                    "id": "63d5832dab26e85596c14ea0",
                    "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
                    "subscriptionId": null,
                    "userId": 12,
                    "pendingTransactionId": null,
                    "categoryId": "22016000",
                    "category": [
                        "Travel",
                        "Taxi"
                    ],
                    "tags": null,
                    "location": {
                        "address": null,
                        "city": null,
                        "region": null,
                        "postalCode": null,
                        "country": null,
                        "lat": null,
                        "lon": null,
                        "storeNumber": null
                    },
                    "name": "Uber 072515 SF**POOL**",
                    "originalDescription": null,
                    "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
                    "amount": 6.33,
                    "absAmount": 6.33,
                    "isoCurrencyCode": "GBP",
                    "unofficialCurrencyCode": null,
                    "date": "2023-01-13",
                    "pending": false,
                    "transactionId": "Z1Ev1rvlvbtgyl1anNkBs14egNXJbMIr3Vb5o",
                    "merchantName": "Uber",
                    "authorizedDate": "2023-01-13",
                    "dayMonth": "12-01",
                    "monthYear": "01-2023",
                    "dateString": "2023-01-13",
                    "dayNumber": null,
                    "year": null,
                    "dateAdded": "2023-01-28T20:18:53.315",
                    "dateUpdated": "2023-01-28T20:18:53.629"
                }
            ],
            maxPageSize: 0,
            pageNumber: 0,
            pageNumberArray: [],
            minDate: moment().startOf('month').format("YYYY-MM-DD")
        }
    },

    mounted() {
        // this.getBanks()
        // this.getSubscriptions()
    },

    watch: {
        maxPageSize() {
            // get array of numbers from 0 to maxPageSize
            this.pageNumberArray = Array.from({ length: this.maxPageSize + 1 }, (_, i) => i)
        },
        pageNumber() {
            this.getTransactions()
        },
        transactionDate() {
            this.pageNumber = 0
            this.getMaxPageSize()
            this.getTransactions()
        },
    },

    computed: {
        formatedTransactionDate() {
            return this.transactionDate !== null ? moment(this.transactionDate).format('yyyy') : null
        },
        userId() {
            return this.$session.get('user').id
        },
        years() {
            return Array.from({ length: 100 }, (_, i) => moment().subtract(i, 'years').format('YYYY'))
        },
    },

    methods: {
        async getBanks() {
            // try {
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/items', { user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
            //     this.banks = response.data.banks
            // } catch (error) {
            //     // concat error message
            //     let errorMessage = ''
            //     error.response.data.errors.forEach(element => {
            //         errorMessage += element + ', '
            //     });
            //     this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     // console.log('Failed to load user banks'); 
            // }
        },
        async getSubscriptions() {
            // try {
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/all', { user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
            //     this.subscriptions = response.data
            // } catch (error) {
            //     // concat error message
            //     let errorMessage = ''
            //     error.response.data.errors.forEach(element => {
            //         errorMessage += element + ', '
            //     });
            //     this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     // console.log('Failed to load user subscriptions'); 
            // }
        },
        async getTransactions() {
            // this.transactions = []
            // if (JSON.stringify(this.viewingItem) !== "{}") {
            //     try {
            //         let payload = {
            //             user_id: this.userId,
            //             subscription_id: this.viewingItem.id,
            //             page_number: this.pageNumber,
            //             year: this.formatedTransactionDate
            //         }

            //         const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/transactions', payload, { headers: { "Content-Type": "application/json" } })
            //         this.transactions = response.data.transactions
            //     } catch (error) {
            //         // concat error message
            //         let errorMessage = ''
            //         error.response.data.errors.forEach(element => {
            //             errorMessage += element + ', '
            //         });
            //         this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //         // console.log('Failed to load subscription transactions'); 
            //     }
            // }
        },
        async getMaxPageSize() {
            // let payload = {
            //     user_id: this.userId,
            //     year: this.formatedTransactionDate,
            //     subscription_id: this.viewingItem.id,
            //     page_number: this.pageNumber
            // }
            // const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/transactions/max-page', payload, { headers: { "Content-Type": "application/json" } })
            // this.maxPageSize = response.data
        },
        async save() {
            // try {
            //     this.loading = true
            //     let payload = {
            //         user_id: this.userId,
            //         name: this.formData.name,
            //         merchant_name: this.formData.merchantName,
            //         amount: this.formData.amount,
            //         due_date: this.formData.dueDate,
            //         item_id: this.formData.itemId,
            //         bank_name: this.getBankName(this.formData.itemId)
            //     }
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/add', payload, { headers: { "Content-Type": "application/json" } })
            //     this.message = response.data
            //     this.getSubscriptions()
            //     this.showForm = false
            //     this.loading = false
            // } catch (error) {
            //     this.loading = false
            //     // concat error message
            //     let errorMessage = ''
            //     error.response.data.errors.forEach(element => {
            //         errorMessage += element + ', '
            //     });
            //     this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     // console.log('Failed to save subscription'); 
            // }
        },
        openForm() {
            this.showForm = true
        },
        hideForm() {
            this.showForm = false
        },
        async update() {
            // try {
            //     this.loading = true
            //     let payload = {
            //         id: this.editItem.id,
            //         user_id: this.editItem.userId,
            //         name: this.editItem.name,
            //         merchant_name: this.editItem.merchantName,
            //         amount: this.editItem.amount,
            //         due_date: this.editItem.dueDate,
            //         item_id: this.editItem.itemId,
            //         bank_name: this.editItem.bankName
            //     }
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/edit', payload, { headers: { "Content-Type": "application/json" } })
            //     this.message = response.data
            //     this.getSubscriptions()
            //     this.hideEditForm()
            //     this.loading = false
            // } catch (error) {
            //     this.loading = false
            //     // concat error message
            //     let errorMessage = ''
            //     error.response.data.errors.forEach(element => {
            //         errorMessage += element + ', '
            //     });
            //     this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     // console.log('Failed to update subscription'); 
            // }
        },
        hideEditForm() {
            this.editFormVisible = false
        },
        showEditForm(item) {
            this.editFormVisible = true
            this.editItem.name = item.name
            this.editItem.merchantName = item.merchantName
            this.editItem.userId = this.userId
            this.editItem.id = item.id
            this.editItem.amount = item.amount
            this.editItem.dueDate = item.dueDate
            this.editItem.itemId = item.itemId,
                this.editItem.bankName = item.bankName
        },
        viewItem(item) {
            this.viewingItem = item
            this.pageNumber = 0
            this.transactionDate = null
            this.getMaxPageSize()
            this.getTransactions()
            this.steps = 2
        },
        deleteItem(item) {
            this.deletingItem = item
            // this.deleteDialog = true
            this.deleteDialog = false
        },
        cancelDelete() {
            this.deleteDialog = false
        },
        async confirmDelete() {
            // try {
            //     let payload = {
            //         id: this.deletingItem.id,
            //         user_id: this.userId
            //     }
            //     const response = await axios.post(process.env.VUE_APP_API_URL + '/subscriptions/delete', payload, { headers: { "Content-Type": "application/json" } })
            //     this.message = response.data
            //     this.getSubscriptions()
            //     this.deleteDialog = false
            // } catch (error) {
            //     // this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.error, type: Messages.ERROR})
            //     // concat error message
            //     let errorMessage = ''
            //     error.response.data.errors.forEach(element => {
            //         errorMessage += element + ', '
            //     });
            //     this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
            //     console.log('Failed to delete subscription');
            // }
        },
        getBankName(value) {
            // this.banks.find(x => x.itemId === itemId).bankName;
            var item = this.banks.find(item => item.itemId === value);
            // console.log(item);
            return item.bank || null
        },
    }
}
</script>

<style></style>