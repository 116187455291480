<template>
    <div class="flex">
        <v-card-title sm class="text-sm font-medium "><span class="text-sm">{{ name }}</span></v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle>{{ value }}</v-card-subtitle>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            required: true
        }
    }

}
</script>

<style></style>