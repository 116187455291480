<template>
    <div>
        <v-card-title>
            <span class="headline">Transaction Details</span>
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <div class="my-4">
                    <div class="space-y-3">
                        <div class="mb-2 flex flex-col w-full">
                            <transaction-detail-item name="Name" :value="item.name"></transaction-detail-item>
                            <transaction-detail-item name="Category"
                                :value="categories.join(', ')"></transaction-detail-item>
                            <transaction-detail-item name="Date" :value="item.date"></transaction-detail-item>
                            <transaction-detail-item name="Amount"
                                :value="getAmountSign(item.amount) + item.amount"></transaction-detail-item>
                            <transaction-detail-item name="Currency"
                                :value="item.isoCurrencyCode"></transaction-detail-item>
                            <transaction-detail-item name="Tags"
                                :value="tags.join(', ')"></transaction-detail-item>

                            <div class="flex justify-between mt-8 border-t-2 pt-4">
                                <!-- add CATEGORY dialog -->
                                <v-dialog v-model="categoryDialog" persistent max-width="300px" color="red lighten-1">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card-subtitle>
                                            <v-btn v-on="on" v-bind="attrs" small rounded color="red lighten-1" class="shadow-lg"><span
                                                    class="text-white">
                                                    Category</span><v-icon right color="white">mdi-pencil</v-icon></v-btn>
                                        </v-card-subtitle>
                                    </template>
                                    <v-card rounded="xl" class="p-2">
                                        <v-card-title>
                                            <span class="headline">Category </span>
                                        </v-card-title>
                                        <v-card-text class="mt-2 mb-8">
                                            <v-chip color="red lighten-1" text-color="white" v-for="(category, i) in categories" :key="i"
                                                :value="category" class="ma-2 cursor-default">
                                                {{ category }}
                                                <v-icon @click="removeCategory(category)" class="px-4" small right>mdi-close</v-icon>
                                            </v-chip>
                                        </v-card-text>

                                        <div class="flex justify-between mx-4">
                                            <v-text-field @keydown.enter="addCategory()" v-model="newCategory" dense label="Add Category" outlined></v-text-field>                                            
                                            <v-btn @click="addCategory()" rounded elevation="2" color="red lighten-1" class="mx-4"><v-icon color="white">mdi-plus</v-icon></v-btn>
                                        </div>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-actions class="my-4">
                                            <v-btn color="red lighten-1" text @click="categoryDialog = false">Cancel</v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="lighten-1" text @click="updateTransaction()">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <!-- end of add CATEGORY dialog -->

                                <!-- add TAG dialog -->
                                <v-dialog v-model="tagDialog" persistent max-width="300px" color="red lighten-1">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card-subtitle>
                                            <v-btn v-on="on" v-bind="attrs" small rounded color="red lighten-1" class="shadow-lg"><span
                                                    class="text-white">
                                                    Tags</span><v-icon right color="white">mdi-pencil</v-icon></v-btn>
                                        </v-card-subtitle>
                                    </template>
                                    <v-card rounded="xl" class="p-2">
                                        <v-card-title>
                                            <span class="headline">Tags </span>
                                        </v-card-title>
                                        <v-card-text class="mt-2 mb-8">
                                            <v-chip color="red lighten-1" text-color="white" v-for="(tag, i) in tags" :key="i"
                                                :value="tag" class="ma-2 cursor-default">
                                                {{ tag }}
                                                <v-icon @click="removeTag(tag)" class="px-4" small right>mdi-close</v-icon>
                                            </v-chip>
                                        </v-card-text>

                                        <div class="flex justify-between mx-4">
                                            <v-text-field @keydown.enter="addTag()" v-model="newTag" dense label="Add Tag" outlined></v-text-field>                                            
                                            <v-btn @click="addTag()" rounded elevation="2" color="red lighten-1" class="mx-4"><v-icon color="white">mdi-plus</v-icon></v-btn>
                                        </div>

                                        <v-divider class="mx-4"></v-divider>

                                        <v-card-actions class="my-4">
                                            <v-btn color="red lighten-1" text @click="tagDialog = false">Cancel</v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="lighten-1" text @click="updateTransaction()">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <!-- end of add TAG dialog -->
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </v-card-text>
    </div>
</template>

<script>
import TransactionDetailItem from './TransactionDetailItem.vue'
import { Messages } from '@/utils/constants';
import axios from 'axios'
export default {
    components: { TransactionDetailItem },
    data() {
        return {
            categoryDialog: false,
            tagDialog: false,
            newCategory: '',
            newTag: '',
            copiedItem: {},
        }
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.copiedItem = Object.assign({}, this.item)
    },
    computed: {
        categories: {
            get() {
                return this.item.category !== undefined && this.item.category !== null && this.item.category.length > 0 ? this.item.category : []
            },
            set(value) {
                this.item.category = value
            }
        },
        tags: {
            get() {
                return this.item.tags !== undefined && this.item.tags !== null && this.item.tags.length > 0 ? this.item.tags : []
            },
            set(value) {
                this.item.tags = value
            }
        }
    },
    methods: {
        getAmountSign(amount) {
            return amount < 0 ? '-' : '+'
        },
        removeCategory(category) {
            this.categories = this.categories.filter(item => item !== category)
            this.copiedItem.category = this.categories
        },
        addCategory() {
            if (this.newCategory) {
                this.categories.push(this.newCategory)
                this.copiedItem.category = this.categories
                this.newCategory = ''
            }
        },
        removeTag(tag) {
            this.tags = this.tags.filter(item => item !== tag)
            this.copiedItem.tags = this.tags
        },
        addTag() {
            if (this.newTag) {
                this.tags.push(this.newTag)
                this.copiedItem.tags = this.tags
                this.newTag = ''
            }
        },
        updateTransaction() {
            this.dialog = false
            // check transaction data has changed
            if (JSON.stringify(this.copiedItem) !== JSON.stringify(this.item)) {
                axios.post(process.env.VUE_APP_API_URL + '/transactions/update', this.copiedItem)
                    .then(() => {
                        this.$store.dispatch('showSnackBar', { status: true, message: 'Transaction updated', type: Messages.SUCCESS })
                        this.categoryDialog = false
                        this.tagDialog = false
                    })
                    .catch(error => {
                        this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.errors, type: Messages.ERROR })
                    })
            } 
        }
    },
}
</script>

<style></style>